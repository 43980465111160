import { api } from 'services/api';
import { IRegisterForm, ISignInForm, ISignRes } from 'interfaces';

export const signUp = async (data: IRegisterForm): Promise<Boolean> => {
    let response = await api.post('auth/signup', data);
    return response.data;
}
export const signIn = async (data: ISignInForm): Promise<ISignRes> => {
    let response = await api.post('auth/login', data);
    return response.data;
}
export const getTopUpHistory = async (apiKey: string, apiSecret: string) => {
    const response = await api.get(`bybit-api/top-up-history`, {
      params: { apiKey, apiSecret },
    });
    return response.data;
  };
  
  export const getCopyTradingHistory = async (apiKey: string, apiSecret: string) => {
    const response = await api.get(`bybit-api/copy-trading-relationships`, {
      params: { apiKey, apiSecret },
    });
    return response.data;
  };
  
  export const getKycLevel = async (apiKey: string, apiSecret: string, uid: string) => {
    const response = await api.get(`bybit-api/kyc-level`, {
      params: { apiKey, apiSecret, uid },
    });
    return response.data;
  };
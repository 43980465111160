import React, { useState } from 'react';
import styles from './index.module.scss';
import { Button } from 'ui/buttons';
import { useQuery } from 'react-query';
import { getTopUpHistory, getCopyTradingHistory, getKycLevel } from 'api';
import { sliceTextWithPoint } from 'utils/addressSlice';

export const Main = () => {
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [uid, setUid] = useState('');

  const { data: topUpData, refetch: refetchTopUp, isFetching: isLoadingtopUpHistory } = useQuery(
    ['topUpHistory', apiKey, apiSecret],
    () => getTopUpHistory(apiKey, apiSecret),
    { enabled: false }
  );

  const { data: copyTradingData, refetch: refetchCopyTrading, isFetching: isLoadingcopyTradingData } = useQuery(
    ['copyTradingHistory', apiKey, apiSecret],
    () => getCopyTradingHistory(apiKey, apiSecret),
    { enabled: false }
  );

  const { data: kycData, refetch: refetchKyc, isFetching: isLoadingKYC } = useQuery(
    ['kycLevel', apiKey, apiSecret, uid],
    () => getKycLevel(apiKey, apiSecret, uid),
    { enabled: false }
  );

  const handleSubmit = async () => {
    await refetchTopUp();
    await refetchCopyTrading();
    await refetchKyc();
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Bybit API Dashboard</h1>
      <div className={styles.formGroup}>
        <label className={styles.label}>API Key</label>
        <input
          className={styles.input}
          type="text"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>API Secret</label>
        <input
          className={styles.input}
          type="password"
          value={apiSecret}
          onChange={(e) => setApiSecret(e.target.value)}
        />
      </div>
      <div className={styles.formGroup}>
        <label className={styles.label}>UID</label>
        <input
          className={styles.input}
          type="text"
          value={uid}
          onChange={(e) => setUid(e.target.value)}
        />
      </div>
      <Button isLoading={isLoadingKYC || isLoadingcopyTradingData || isLoadingtopUpHistory} className={styles.button} onClick={handleSubmit}>
        Submit
      </Button>

      {kycData && (
        <div className={styles.infoContainer}>
          <div className={styles.infoBlock}>
            <h2 className={styles.infoTitle}>KYC Status</h2>
            <p className={styles.infoText}>{kycData.kycLevel}</p>
          </div>
          <div className={styles.infoBlock}>
            <h2 className={styles.infoTitle}>Sponsor UID</h2>
            <p className={styles.infoText}>{kycData.inviterID}</p>
          </div>
        </div>
      )}

      {copyTradingData && (
        <div className={styles.historyContainer}>
          <h2 className={styles.historyTitle}>Copy Trading History</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Transfer ID</th>
                <th>Coin</th>
                <th>Amount</th>
                <th>From Account</th>
                <th>To Account</th>
                <th>Status</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {copyTradingData.map((record: any, index: number) => (
                <tr key={index}>
                  <td>{record.transferId}</td>
                  <td>{record.coin}</td>
                  <td>{record.amount}</td>
                  <td>{record.fromAccountType}</td>
                  <td>{record.toAccountType}</td>
                  <td>{record.status}</td>
                  <td>{new Date(parseInt(record.timestamp)).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {topUpData && (
        <div className={styles.historyContainer}>
          <h2 className={styles.historyTitle}>Top Up History</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Coin</th>
                <th>Chain</th>
                <th>Amount</th>
                <th>Tx ID</th>
                <th>Status</th>
                <th>To Address</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {topUpData.rows.map((record: any, index: number) => (
                <tr key={index}>
                  <td>{record.coin}</td>
                  <td>{record.chain}</td>
                  <td>{record.amount}</td>
                  <td>{sliceTextWithPoint(record.txID)}</td>
                  <td>{record.status}</td>
                  <td>{sliceTextWithPoint(record.toAddress)}</td>
                  <td>{new Date(parseInt(record.successAt)).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

import { Routes, Route, useLocation } from "react-router-dom";
import { Main } from './pages/Main';
import { useJwtStore } from 'stores/jwt';
import { RequireAuth } from 'utils/requireAuth';
import { RequireRole } from 'utils/requireRole';
import { AxiosJWT } from "services/axiosJWT";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <AxiosJWT />
      <ToastContainer />
      <Routes>
        <Route path={"/"} element={<Main />}></Route>
        <Route element={<RequireAuth />} >
          {/*  <Route path='/cabinet/*' element={<Cabinet />}></Route> */}
        </Route>
        <Route element={<RequireRole />} >
          {/* <Route path='/admin-panel/*' element={<AdminPanel />}></Route> */}
        </Route>
      </Routes>
      {/* {location.pathname.startsWith('/admin-panel') ? null : <Footer />} */}
    </>
  );
}

export default App;
